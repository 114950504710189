import { Component, OnInit, Input} from '@angular/core';
import { PrincipalService } from 'src/app/shared/services/principal.service';
import { Menu } from 'src/app/shared/model/menu';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {
  public urlImage:string;
  menus :Array<Menu>
  constructor(private principalService:PrincipalService) { 
    this.urlImage = environment.imageServe; 
  }

  ngOnInit() {
    this.getMenu();
   }

  openMegaMenu(){
    let pane = document.getElementsByClassName('cdk-overlay-pane');
    [].forEach.call(pane, function (el) {
        if(el.children.length > 0){
          if(el.children[0].classList.contains('mega-menu')){
            el.classList.add('mega-menu-pane');
          }
        }        
    });
  }

  public getMenu(){
    this.principalService.getMenu().subscribe(
      response=>{
        this.menus =response;
      },error =>{
        console.log(error);
      }
    )
  }
}
