import { Injectable } from '@angular/core';
import { Slide } from './../model/slide';
import { Banner } from './../model/banner';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from './../../../environments/environment'; 
import { Observable } from 'rxjs';
import { SwiperRenderSlideFunction } from 'ngx-swiper-wrapper';
import { ViewArticle } from '../model/viewArticle';
import { ViewCategory } from '../model/viewCategory';
import { Menu } from '../model/menu';
import { ViewBranchOffice } from '../model/viewBranchOffice';
import { ViewLogContact } from '../model/viewLogContact';
import { Respuesta } from '../model/respuesta';
import { ViewNewsLetter } from '../model/viewNewsLetter';

@Injectable({
  providedIn: 'root'
})
export class PrincipalService {

  constructor(
    private http: HttpClient,
  ) { }

  getSlides(): Observable<Slide[]>  {
    return this.http.get<Slide[]>(`${environment.apiURL}/principal/getSlide`); 
  }

  getBanners(): Observable<Banner[]>  {
    return this.http.get<Banner[]>(`${environment.apiURL}/principal/getBanner`); 
  }
  
  getFeatured(slide:number): Observable<ViewArticle[]>  {
    return this.http.get<ViewArticle[]>(`${environment.apiURL}/principal/getFeatured/${slide}`); 
  }

  getSearchArticle(name:string): Observable<ViewArticle[]>  {
    return this.http.get<ViewArticle[]>(`${environment.apiURL}/principal/searchArticle/${name}`); 
  }

  getCategorySeeker(): Observable<ViewCategory[]>  {
    return this.http.get<ViewCategory[]>(`${environment.apiURL}/principal/getCategorySeeker`); 
  }

  getBranchOffice(): Observable<ViewBranchOffice[]>  {
    return this.http.get<ViewBranchOffice[]>(`${environment.apiURL}/principal/getBranchOffice`); 
  }

  getMenu(): Observable<Menu[]>  {
    return this.http.get<Menu[]>(`${environment.apiURL}/principal/getMenu`); 
  }
  
  sendContact(viewLogContact:ViewLogContact): Observable<Respuesta>{
    const headerss = new HttpHeaders({'Content-Type': 'application/json'});
    return this.http.post<Respuesta>(`${environment.apiURL}/principal/saveLogContact`, JSON.stringify(viewLogContact), { headers: headerss});
  }
  
  registerNeswLetter(viewNewsLetter:ViewNewsLetter): Observable<Respuesta>{
    const headerss = new HttpHeaders({'Content-Type': 'application/json'});
    return this.http.post<Respuesta>(`${environment.apiURL}/principal/registerNewsLetter`, JSON.stringify(viewNewsLetter), { headers: headerss});
  }

  /*getEmpleado(id: number): Observable<Empleado>{
    return this.http.get<Empleado>(`${environment.apiURL}/catalog/getEmplyByID/${id}`);
  }*/

  /*createEmpleado(empleado): Observable<any>{
    const headerss = new HttpHeaders({'Content-Type': 'application/json'});
    return this.http.post<Empleado>(`${environment.apiURL}/catalog/createEmploye`, JSON.stringify(empleado), { headers: headerss});
  }*/

}
